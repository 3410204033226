/* Configura el contenedor para que ocupe toda la pantalla */
.gallery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #fff;
    opacity: 0; /* Inicialmente oculto */
    transition: opacity 1s ease-in-out; /* Transición para la opacidad */
  }
  
  .gallery.loaded {
    opacity: 1; /* Mostrar el contenedor cuando esté cargado */
  }
  
  .gallery-image {
    width: 80%;
    max-width: 800px;
    margin: 10px;
    opacity: 0; /* Inicialmente oculto */
    transform: scale(0.9);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  }
  
  /* Animaciones para las imágenes */
  .gallery.loaded .gallery-image {
    opacity: 1;
    transform: scale(1);
  }
  
  .gallery-image:nth-child(1) {
    animation: fadeIn 2s ease-out 1s forwards;
  }
  
  .gallery-image:nth-child(2) {
    animation: fadeIn 2s ease-out 2s forwards;
  }
  
  .gallery-image:nth-child(3) {
    animation: fadeIn 2s ease-out 3s forwards;
  }
  
  /* Keyframes para la animación de aparición */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  