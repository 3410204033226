/* Estilos para la navegación móvil */
.mobile-navigation {
  list-style-type: none; /* Quita los puntos de la lista */
  padding: 0; /* Quita el padding de la lista */
  margin: 0; /* Quita el margen de la lista */
  display: flex; /* Hace que los elementos de la lista se alineen en fila */
  flex-direction: column; /* Alinea los elementos en columna */
  background-color: #f8f9fa; /* Color de fondo */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para darle profundidad */
}

.mobile-navigation li {
  margin: 8px 0; /* Espaciado entre los elementos de la lista */
}

.mobile-navigation li:first-child {
  margin-top: 16px; /* Espaciado superior para el primer elemento */
}

.mobile-navigation li:last-child {
  margin-bottom: 16px; /* Espaciado inferior para el último elemento */
}

.mobile-navigation button {
  width: 100%; /* Hace que el botón ocupe todo el ancho del contenedor */
  text-align: left; /* Alinea el texto a la izquierda */
  padding: 12px; /* Padding interno del botón */
  color: #000000; /* Color del texto del botón */
  background-color: transparent; /* Fondo transparente */
  border: none; /* Sin borde */
  border-radius: 4px; /* Bordes redondeados */
  transition: background-color 0.3s ease; /* Transición suave para el fondo */
}

.mobile-navigation button:hover {
  background-color: #e2e6ea; /* Fondo al pasar el ratón */
}

.mobile-navigation button:focus {
  outline: none; /* Quita el borde del foco */
  background-color: #d6d8db; /* Fondo al enfocar */
}

.detailBar {
  position: relative; /* Para que el Typography y la imagen se posicionen correctamente */
}

.detailBar img {
  position: absolute;
  right: 13vh; /* Alineado a la derecha */
  top: 40%; /* Centrado verticalmente */
  transform: translateY(-50%); /* Ajusta la posición vertical */
  z-index: 1; /* Asegura que la imagen esté debajo del texto */
  max-height: 100%; /* Ajusta la altura de la imagen si es necesario */
}

.appbar {
  overflow: visible;
}

.backgDetailNavbar {
  background-image: url("../images/FeedIG/DetailNavBar.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
}
.circleContainer {
  display: flex;

  justify-items: center;
  align-items: center;
  height: 115px;
  width: 115px;
  border-radius: 50%;
  z-index: 1;
  position: absolute; /* Posiciona el círculo fuera del flujo del documento */
  top: 50%; /* Ajusta el top al 50% de la altura del Toolbar */
  left: 30%;
  transform: translate(
    -50%,
    -50%
  ); /* Centra el círculo horizontal y verticalmente */
}
