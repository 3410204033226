/* General styles for the container */
.containereffect {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: transparent;
  }
  
  /* Styles for the circles */
  .circle {
    position: absolute;
    width: 25px; /* Slightly elongated to resemble a leaf shape */
    height: 15px;
    background-color: #50a41be1; /* Green color to resemble a leaf */
    border-radius: 60% 40% 60% 40% / 40% 60% 40% 60%; /* Leaf-like shape */
    opacity: 0.9;
    animation: fallLeaf linear infinite;
    transform: rotate(45deg); /* Rotates to give a more natural leaf orientation */
  }
  
  /* Position circles randomly */
  .circle:nth-child(1) { left: 10%; animation-duration: 6s; animation-delay: 0s; }
  .circle:nth-child(2) { left: 20%; animation-duration: 4s; animation-delay: 1s; }
  .circle:nth-child(3) { left: 30%; animation-duration: 5s; animation-delay: 2s; }
  .circle:nth-child(4) { left: 40%; animation-duration: 3s; animation-delay: 3s; }
  .circle:nth-child(5) { left: 50%; animation-duration: 7s; animation-delay: 4s; }
  .circle:nth-child(6) { left: 60%; animation-duration: 4s; animation-delay: 5s; }
  .circle:nth-child(7) { left: 70%; animation-duration: 5s; animation-delay: 6s; }
  .circle:nth-child(8) { left: 80%; animation-duration: 6s; animation-delay: 7s; }
  .circle:nth-child(9) { left: 90%; animation-duration: 4s; animation-delay: 8s; }
  
  /* Animation for falling circles */
  @keyframes fallLeaf  {
    0% {
      top: -20px;
      transform: translateX(0);
    }
    100% {
      top: 100%;
      transform: translateX(50px);
    }
  }
  